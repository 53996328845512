const ADDRESS_COMPONENTS = {
  subpremise : 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  neighborhood: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'long_name',
  country: 'long_name',
  postal_code: 'short_name'
};

export var MapMixin = {

  computed: {},
  methods: {
    setUserLocationOnMap(force = false) {

      console.log("Setting User's current location!")

      let savedLocation = this.$localStorage.get('userlocation')
      if (!force && savedLocation && savedLocation.latitude) {
        this.$store.dispatch('saveUserLocation', savedLocation)
      } else {
        let userLocation = this.$store.dispatch('setUserLocation')
        userLocation
          .then(p => {
            if (p != null) {
              console.log('Saving current location to to local storage')
              this.$localStorage.set('userlocation', p)
            } else {
              console.error(
                'Unable to get the location, setting to saved location'
              )
              if (savedLocation && savedLocation.latitude) {
                this.$store.dispatch('saveUserLocation', savedLocation)
              }
            }
          })
          .catch(e => console.error('Unable to get the location', e))
      }
    },
     formatResult  (place)  {
      let returnData = {};
      for (let i = 0; i < place.address_components.length; i++) {
          let addressType = place.address_components[i].types[0];
          if (ADDRESS_COMPONENTS[addressType]) {
              let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
              returnData[addressType] = val;
          }
      }
      returnData['latitude'] = place.geometry.location.lat;
      returnData['longitude'] = place.geometry.location.lng;
      return returnData
    },
    getAddressData2(placeResultData) {

      if (!placeResultData) {
        console.warn('missing placeResultData',placeResultData )
        return
      }
      let addressData = this.formatResult(placeResultData)

      console.log('addressData', addressData)

      let address = {}

      address.address1 =
        (addressData.street_number || '') + ' ' + (addressData.route || '')
      address.city = addressData.locality || ''
      address.state = addressData.administrative_area_level_1 || ''
      address.zipcode = addressData.postal_code || ''
      address.latitude = addressData.latitude || ''
      address.neighborhood =addressData.neighborhood
      address.longitude = addressData.longitude || ''
      console.log('Address is', address)
      return address
    },
    reverseGeocode() {
      let getAddressData =  this.getAddressData2
      let userLocation = this.$store.dispatch('setUserLocation')
      return userLocation
        .then(p => {
          if (p != null) {
            var geocoder = new google.maps.Geocoder()
            var latlng = { lat: p.latitude, lng: p.longitude }

            return new Promise(function(resolve, reject) {
              geocoder.geocode({ location: latlng }, function(results, status) {
                  if (status === 'OK') {
                      console.log(results);
                      let addr = getAddressData(results[0])
                      addr.location = p

                      resolve(addr);
                      //resolve([results[0]]);
                  } else {
                      reject(new Error('Couldnt\'t find the location ' + location));
                  }
          })
          })


            //  geocoder.geocode({ location: latlng }, function(results, status) {
            //   if (status === 'OK') {
            //     if (results[0]) {
            //       console.log('Found address', results)
            //       console.log('callback', callback)
            //       callback(results[0])
            //     } else {
            //       console.warn('No results found')
            //     }
            //   }
            // })
          }
        })

    }
  }
}
